<template>
  <div class="device-manage" ref="container">
    <div class="container-left" :style="{ height: '100%' }">
      <el-input
        placeholder="输入关键字进行过滤"
        style="margin-bottom: 5px;"
        size="small"
        v-model="filterText"
      />
      <div class="container-left-tree">
        <el-tree
          ref="tree"
          :data="orgTree"
          :props="defaultProps"
          node-key="id"
          highlight-current
          @node-click="treeNodeClick"
          :expand-on-click-node="false"
          :default-expanded-keys="defaultExpanded"
          :filter-node-method="filterNode"
        ></el-tree>
      </div>
    </div>
    <div class="container-right" ref="right">
      <div
        class="buttonOut"
        ref="buttonOut"
        style="flex-wrap: wrap; height: auto;"
      >
        <div>
          <span class="mr1">设备号</span>
          <el-input
            v-model="queryParams.id"
            clearable
            size="small"
            style="width: 150px"
            placeholder="请输入设备号"
          />
        </div>
        <div>
          <span class="mr1">车牌号</span>
          <el-input
            v-model="queryParams.carPlateNum"
            clearable
            size="small"
            style="width: 150px"
            placeholder="请输入车牌号"
          />
        </div>

        <div>
          <span class="mr1">车牌颜色</span>
          <el-select
            v-model="queryParams.carPlateColor"
            clearable
            size="small"
            style="width: 150px"
            placeholder="请选择车牌颜色"
          >
            <el-option label="蓝" value="蓝"></el-option>
            <el-option label="黄" value="黄"></el-option>
            <el-option label="黑" value="黑"></el-option>
            <el-option label="白" value="白"></el-option>
            <el-option label="绿" value="绿"></el-option>
          </el-select>
        </div>
        <!-- <div>
          <span class="mr1">设置时间</span>
          <el-date-picker
            v-model="setTime"
            type="datetimerange"
            size="small"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            @change="(event) => handleTime(event, 'setTime')"
            style="width: 420px;"
          >
          </el-date-picker>
        </div> -->
        <div>
          <span class="mr1">车辆归属地</span>
          <el-select
            v-model="queryParams.belong"
            clearable
            size="small"
            style="width: 150px"
            placeholder="请选择车辆归属地"
          >
            <el-option label="未分类" :value="0"></el-option>
            <el-option label="地市车辆" :value="1"></el-option>
            <el-option label="中直车辆" :value="2"></el-option>
            <el-option label="省直车辆" :value="3"></el-option>
            <el-option label="租赁车辆" :value="4"></el-option>
            <el-option label="内部车辆" :value="5"></el-option>
            <el-option label="公务快车" :value="6"></el-option>
            <el-option label="自动连接一网通和省级平台" :value="7"></el-option>
          </el-select>
        </div>
        <div>
          <span class="mr1">连接省平台状态</span>
          <el-select
            v-model="queryParams.shengStatus"
            clearable
            size="small"
            style="width: 150px"
            placeholder="请选择"
          >
            <el-option label="等待设置" value="0"></el-option>
            <el-option label="已设置" value="2"></el-option>
            <el-option label="出厂状态" value="3"></el-option>
          </el-select>
        </div>
        <div>
          <span class="mr1">连接市平台状态</span>
          <el-select
            v-model="queryParams.cityStatus"
            clearable
            size="small"
            style="width: 150px"
            placeholder="请选择"
          >
            <el-option label="等待设置" value="0"></el-option>
            <el-option label="已设置" value="2"></el-option>
            <el-option label="出厂状态" value="3"></el-option>
          </el-select>
        </div>
        <div style="padding-left: 10px;">
          <el-button
            type="primary"
            style="margin: 5px 0;"
            size="small"
            @click="currentChangeHandle(1)"
          >
            查询
          </el-button>
          <el-button size="small" @click="reset">
            重置
          </el-button>
          <!-- <el-button size="small" @click="handleSetIp(null)"
            >批量设置IP</el-button
          > -->
          <el-button size="small" type="primary" @click="handleCarBelong()">
            设置车辆归属
          </el-button>
          <el-button size="small" @click="handleExport">导出</el-button>
        </div>
      </div>
      <div style="width: 100%; padding: 8px">
        <el-table
          :data="tableData"
          :height="clientHeight + 'px'"
          border
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            class="selection"
            type="selection"
            width="55"
            align="center"
          >
          </el-table-column>
          <el-table-column
            type="index"
            width="55"
            label="序号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="id"
            label="设备号"
            min-width="120"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="车牌号"
            min-width="200"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span>{{ scope.row.carPlateNum }}</span>
              <!-- 地市车辆显示 city -->
              <el-tag
                :type="tagDict.get(scope.row.belong)"
                class="ml10"
                size="mini"
                v-if="
                  (scope.row.belong !== 3 && scope.row.city) ||
                    [2, 3, 4].includes(scope.row.belong)
                "
              >
                <template v-if="scope.row.belong !== 3">
                  {{ belongDict.get(scope.row.belong) }}({{
                    cityDict.get(scope.row.city)
                  }})
                </template>
                <template v-else>
                  {{ belongDict.get(scope.row.belong) }}
                </template>
                <!-- {{
                  scope.row.belong !== 3
                    ? cityDict.get(scope.row.city)
                    : belongDict.get(scope.row.belong)
                }} -->
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="carPlateColor"
            label="车牌颜色"
            min-width="80"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="nodeName"
            label="单位名称"
            min-width="100"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="是否关闭单北斗信号"
            align="center"
            width="150"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span>{{ scope.row.strictStatus === 1 ? "是" : "否" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="设备当前状态">
            <el-table-column label="市平台" width="130" align="center">
              <template slot-scope="scope">
                <el-tooltip
                  :disabled="
                    scope.row.ywtStatusData.name !== '正在取消连接' &&
                      scope.row.ywtStatusData.name !== '正在设置连接'
                  "
                  placement="top"
                >
                  <div slot="content">
                    <template v-if="scope.row.modifyTimeForYwt">
                      <span
                        v-if="scope.row.ywtStatusData.name === '正在取消连接'"
                      >
                        取消时间：{{ scope.row.modifyTimeForYwt }}
                      </span>
                      <span
                        v-if="scope.row.ywtStatusData.name === '正在设置连接'"
                      >
                        设置时间：{{ scope.row.modifyTimeForYwt }}
                      </span>
                      <br />
                    </template>
                    版本号：{{ scope.row.version }}
                  </div>
                  <span :style="{ color: scope.row.ywtStatusData.color }">
                    {{ scope.row.ywtStatusData.name }}
                  </span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="省平台" width="130" align="center">
              <template slot-scope="scope">
                <el-tooltip
                  :disabled="
                    scope.row.jtStatusData.name !== '正在取消连接' &&
                      scope.row.jtStatusData.name !== '正在设置连接'
                  "
                  placement="top"
                >
                  <div slot="content">
                    <template v-if="scope.row.modifyTimeForJt">
                      <span
                        v-if="scope.row.jtStatusData.name === '正在取消连接'"
                      >
                        取消时间：{{ scope.row.modifyTimeForJt }}
                      </span>
                      <span
                        v-if="scope.row.jtStatusData.name === '正在设置连接'"
                      >
                        设置时间：{{ scope.row.modifyTimeForJt }}
                      </span>
                      <br />
                    </template>
                    版本号：{{ scope.row.version }}
                  </div>
                  <span :style="{ color: scope.row.jtStatusData.color }">
                    {{ scope.row.jtStatusData.name }}
                  </span>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            prop="forwardSetTime"
            label="设置时间"
            align="center"
            min-width="160"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="操作"
            min-width="200"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <!-- <el-button
                type="primary"
                size="mini"
                @click="handleSetIp(scope.row)"
              >
                设置IP
              </el-button> -->
              <el-button size="small" @click="handleCarBelong(scope.row)">
                设置车辆归属
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="queryParams.pageNo"
          :page-sizes="
            queryParams.shengStatus !== '' || queryParams.cityStatus !== ''
              ? [100000]
              : [10, 20, 50, 100, 300, 500]
          "
          :page-size="queryParams.pageSize"
          :total="totalCount"
          layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog title="设置IP" :visible.sync="showSetIpModal" width="30%">
      <div>
        <el-checkbox v-model="serverFlag.ywt" true-label="1" false-label="0"
          >一网通平台</el-checkbox
        >
        <el-checkbox v-model="serverFlag.sheng" true-label="1" false-label="0"
          >省平台</el-checkbox
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            showSetIpModal = false;
            serverFlag.ywt = '0';
            serverFlag.sheng = '0';
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="submitSetIp">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="设置车辆归属地"
      :visible.sync="showBelongModal"
      width="600px"
    >
      <div style="height: 50px;">
        <el-radio v-model="belongForm.belong" :label="1"
          >地市车辆 - 自动连接市级平台和省级平台</el-radio
        >
        <el-select
          v-model="city"
          v-if="belongForm.belong === 1"
          size="small"
          placeholder="选择归属地"
        >
          <el-option
            v-for="[value, label] in cityDictArr"
            :label="label"
            :value="value"
            :key="value"
          ></el-option>
        </el-select>
      </div>
      <div style="height: 50px;">
        <el-radio v-model="belongForm.belong" :label="2"
          >中直车辆 - 自动连接市级平台</el-radio
        >
        <el-select
          v-model="city"
          v-if="belongForm.belong === 2"
          size="small"
          placeholder="选择归属地"
        >
          <el-option
            v-for="[value, label] in cityDictArr"
            :label="label"
            :value="value"
            :key="value"
          ></el-option>
        </el-select>
      </div>
      <div style="height: 50px;">
        <el-radio v-model="belongForm.belong" :label="3"
          >省直车辆 - 自动连接省级平台</el-radio
        >
      </div>
      <div style="height: 50px;">
        <el-radio v-model="belongForm.belong" :label="4"
          >租赁车辆 - 自动连接市级平台</el-radio
        >
        <el-select
          v-model="city"
          v-if="belongForm.belong === 4"
          size="small"
          placeholder="选择归属地"
        >
          <el-option
            v-for="[value, label] in cityDictArr"
            :label="label"
            :value="value"
            :key="value"
          ></el-option>
        </el-select>
      </div>
      <div style="height: 50px;">
        <el-radio v-model="belongForm.belong" :label="5"
          >内部车辆 - 自动连接市级平台</el-radio
        >
        <el-select
          v-model="city"
          v-if="belongForm.belong === 5"
          size="small"
          placeholder="选择归属地"
        >
          <el-option
            v-for="[value, label] in cityDictArr"
            :label="label"
            :value="value"
            :key="value"
          ></el-option>
        </el-select>
      </div>
      <div style="height: 50px;">
        <el-radio v-model="belongForm.belong" :label="6">公务快车</el-radio>
      </div>
      <div style="height: 50px;">
        <el-radio v-model="belongForm.belong" :label="7"
          >自动连接一网通和省级平台</el-radio
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            showBelongModal = false;
            equipmentVoList = [];
            belongForm.belong = null;
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="submitCarBelong()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { treeDataTranslate, exportTable } from "@/utils";
const initParams = () => ({
  pageNo: 1, // 页码
  pageSize: 10, // 每页条数
  orgId: "", // 节点id
  carPlateNum: "", // 车牌号
  carPlateColor: "", // 车牌颜色
  id: "", // 设备id
  startForwardTime: "", // 开始转发时间
  endForwardTime: "", // 结束转发时间
  startForwardSetTime: "", // 开始设置时间
  endForwardSetTime: "", // 结束设置时间
  shengStatus: "",
  cityStatus: "",
  belong: "", // 车辆归属地
});

export default {
  name: "jt-device-manage",
  data() {
    const loginData = JSON.parse(localStorage.getItem("login-data"));
    const cityDict = new Map([
      ["shijiazhuang", "石家庄"],
      ["tangshan", "唐山"],
      ["qinhuangdao", "秦皇岛"],
      ["handan", "邯郸"],
      ["xingtai", "邢台"],
      ["baoding", "保定"],
      ["zhangjiakou", "张家口"],
      ["chengde", "承德"],
      ["cangzhou", "沧州"],
      ["langfang", "廊坊"],
      ["hengshui", "衡水"],
      ["xiongan", "雄安"],
      ["xinji", "辛集"],
      ["dingzhou", "定州"],
    ]);
    return {
      showSetIpModal: false, // 控制显示设置IP窗口
      serverFlag: {
        ywt: "0",
        sheng: "0",
      },
      oldServerFlag: {},
      equipmentVoList: [], // 需要设置IP的数据
      selectionData: [], // 表格勾选数据
      $user: loginData,
      orgTree: [],
      defaultProps: {
        children: "children",
        label: "nodeName",
      },
      curNode: {},
      tableData: [],
      totalCount: 0,
      filterText: "",
      clientHeight: 0,
      queryParams: initParams(),
      setTime: ["", ""],
      time: ["", ""],
      ids: "",
      showBelongModal: false, // 车辆归属弹窗
      // 车辆归属表单
      belongForm: {
        belong: null,
        equipmentVoList: "",
      },
      city: "", // 车辆归属地
      belongDict: new Map([
        [1, "地市"],
        [2, "中直"],
        [3, "省直"],
        [4, "租赁"],
        [5, "内部"],
      ]),
      cityDict,
      cityDictArr: [...cityDict],
      tagDict: new Map([
        [1, "info"],
        [2, "success"],
        [3, "warning"],
        [4, ""],
        [5, "danger"],
      ]),
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  computed: {
    defaultExpanded() {
      let _B = Boolean(this.orgTree && this.orgTree.length);
      let arr = _B ? [this.orgTree[0].id] : [];
      return arr;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.clientHeight =
        this.$refs.container.clientHeight -
        this.$refs.buttonOut.clientHeight -
        // 分页高度32px + margin-top 15px
        (32 + 15);
    });
    this.getOrgList();
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.nodeName.indexOf(value) !== -1;
    },
    getOrgList() {
      this.$http({
        url: this.$http.adornUrl("/sys/orgArch/list"),
        methods: "get",
      })
        .then((res) => {
          this.orgTree = treeDataTranslate(res.data.data, "id", "parendId");
          this.curNode = JSON.parse(JSON.stringify(this.orgTree[0]));
          this.getDevList();
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    treeNodeClick(node) {
      this.curNode = JSON.parse(JSON.stringify(node));
      this.currentChangeHandle(1);
    },
    getDevList() {
      if (this.curNode.id == "") return this.$message.warning("请选择单位!");
      const Loading = this.$mask();
      if (
        this.queryParams.shengStatus !== "" ||
        this.queryParams.cityStatus !== ""
      ) {
        this.queryParams.pageSize = 100000;
      }
      this.$http({
        url: this.$http.adornUrl("/obd/forward/list"),
        method: "get",
        params: {
          ...this.queryParams,
          orgId: this.curNode.id,
        },
      })
        .then((res) => {
          Loading.close();
          this.tableData = res.data.page.list.map((el) => {
            // return el;
            return {
              ...el,
              ywtStatusData:
                el.ywtStatus === null || el.ywtStatus === "3"
                  ? this.switchCancelStatus(el.ywtCancel)
                  : this.switchStatus(el.ywtStatus),
              jtStatusData:
                el.jtStatus === null || el.jtStatus === "3"
                  ? this.switchCancelStatus(el.jtCancel)
                  : this.switchStatus(el.jtStatus),
            };
          });
          this.totalCount = res.data.page.totalCount || 0;
        })
        .catch((err) => {
          Loading.close();
          this.$message.error(err.msg);
        });
    },
    switchCancelStatus(status) {
      if (status === "0") {
        return { name: "正在取消连接", color: "#909399" };
      }
      if (status === "2") {
        return { name: "不连接", color: "#F56C6C" };
      }
      if (status === null || status === "3") {
        return { name: "出厂状态", color: "" };
      }
      return { name: `${status} -- ？`, color: "" };
    },
    switchStatus(status) {
      if (status === "0") {
        return { name: "正在设置连接", color: "#E6A23C" };
      }
      if (status === "2") {
        return { name: "已设置", color: "#67C23A" };
      }
      if (status === null || status === "3") {
        return "出厂状态";
      }
      return { name: `${status} -- ？`, color: "" };
    },
    sizeChangeHandle(val) {
      this.queryParams.pageSize = val;
      this.queryParams.pageNo = 1;
      this.getDevList();
    },
    currentChangeHandle(val) {
      this.queryParams.pageNo = val;
      this.getDevList();
    },
    reset() {
      this.queryParams = initParams();
      this.currentChangeHandle(1);
    },
    //导出
    handleExport() {
      const Loading = this.$mask();
      let { pageNo, pageSize, ...args } = this.queryParams;
      this.$http({
        url: this.$http.adornUrl("/obd/exportForward"),
        method: "get",
        responseType: "arraybuffer",
        params: {
          ...args,
          orgId: this.curNode.id,
        },
      })
        .then((res) => {
          Loading.close();
          exportTable(res, "建投设备车辆信息表");
        })
        .catch((err) => {
          Loading.close();
        });
    },
    // 选择时间触发
    handleTime(val, key) {
      if (key === "setTime") {
        // 设置时间
        this.queryParams.startForwardSetTime = val?.[0] ?? "";
        this.queryParams.endForwardSetTime = val?.[1] ?? "";
      }
      if (key === "time") {
        // 转发时间
        this.queryParams.startForwardTime = val?.[0] ?? "";
        this.queryParams.endForwardTime = val?.[1] ?? "";
      }
    },
    handleSelectionChange(val) {
      this.selectionData = val;
      var ids = val.map((el) => el.id).join();
      this.ids = ids;
    },
    setstate(row) {
      this.setStateSubmit(
        row.id,
        `确定对【车牌号:${row.carPlateNum}】进行【设备转省直平台】操作?`
      );
    },
    // 批量操作
    batchSetState() {
      if (!this.ids) return this.$message.warning("请勾选表格数据");
      this.setStateSubmit(this.ids, "确定进行批量【设备转省直平台】操作?");
    },
    setStateSubmit(ids, msg) {
      const Loading = this.$mask();
      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return this.$http({
            url: this.$http.adornUrl("/update/forward/setstate"),
            method: "get",
            params: {
              ids,
            },
          });
        })
        .then((res) => {
          Loading.close();
          this.$message.success(res.data.msg);
          this.getDevList();
        })
        .catch((err) => {
          Loading.close();
          if (err && err.msg) {
            this.$message.error(err.msg);
          }
        });
    },
    async handleSetIp(row) {
      if (row) {
        if (row.strictStatus === 1) {
          return this.$message.warning("该设备已关闭单北斗信号，无法操作");
        }
        // 单个设置
        // 查询回显
        const Loading = this.$mask();
        this.$http({
          url: this.$http.adornUrl("/obd/forward/list"),
          method: "get",
          params: {
            ...initParams(),
            pageNo: 1,
            pageSize: 1,
            id: row.id,
          },
        })
          .then((res) => {
            Loading.close();
            this.equipmentVoList = [
              { equipmentId: row.id, carPlateNum: row.carPlateNum },
            ];
            let data = res.data.page.list[0] ?? {};
            if (["0", "2"].includes(data.ywtStatus)) {
              this.serverFlag.ywt = "1";
            }
            if (["0", "2"].includes(data.jtStatus)) {
              this.serverFlag.sheng = "1";
            }
            this.oldServerFlag = { ...this.serverFlag };
            this.showSetIpModal = true;
          })
          .catch((err) => {
            Loading.close();
            reject();
          });
      } else {
        // 批量设置
        if (!this.selectionData.length) {
          return this.$message.warning("请勾选设备");
        }
        if (this.selectionData.find((el) => el.strictStatus === 1)) {
          return this.$message.warning(
            "已关闭单北斗信号的设备无法操作，请检查勾选数据"
          );
        }
        this.equipmentVoList = this.selectionData.map((el) => {
          return {
            equipmentId: el.id,
            carPlateNum: el.carPlateNum,
          };
        });
        this.showSetIpModal = true;
      }
    },
    submitSetIp() {
      let data = { equipmentVoList: this.equipmentVoList, ...this.serverFlag };
      if (this.serverFlag.ywt === "0" && this.serverFlag.sheng === "0") {
        return this.$message.warning("最少勾选一个！");
      }
      const Loading = this.$mask();
      this.$http({
        url: this.$http.adornUrl("/obd/forward/setIp"),
        method: "post",
        data,
      })
        .then((res) => {
          Loading.close();
          this.$message.success(res.data.msg);
          this.getDevList();
          this.cancelSetIpModal();
        })
        .catch((err) => {
          Loading.close();
          this.$message.error(err.msg);
        });
    },
    cancelSetIpModal() {
      this.showSetIpModal = false;
      this.equipmentVoList = [];
      this.serverFlag = { ywt: "0", sheng: "0" };
      this.oldServerFlag = {};
    },
    // 设置车辆归属
    handleCarBelong(data = null) {
      if (data) {
        // 单个设置
        this.belongForm.equipmentVoList = [
          { equipmentId: data.id, carPlateNum: data.carPlateNum },
        ];
        this.belongForm.belong = data.belong;
        if (data.city) {
          this.city = data.city;
        }
      } else {
        // 批量设置
        if (!this.selectionData.length) {
          return this.$message.warning("请勾选数据");
        }
        this.belongForm.equipmentVoList = this.selectionData.map((el) => {
          return {
            equipmentId: el.id,
            carPlateNum: el.carPlateNum,
          };
        });
      }
      this.showBelongModal = true;
    },
    // 提交车辆归属地
    submitCarBelong() {
      if (![3, 6, 7].includes(this.belongForm.belong) && !this.city) {
        return this.$message.warning("请选择归属地");
      }
      const Loading = this.$mask();
      this.$http({
        url: this.$http.adornUrl("/obd/setBelong"),
        method: "post",
        data: {
          belong: this.belongForm.belong,
          equipmentVoList: this.belongForm.equipmentVoList,
          city: [3, 6, 7].includes(this.belongForm.belong) ? "" : this.city,
        },
      })
        .then((res) => {
          Loading.close();
          this.$message.success(res.data.msg);
          this.getDevList();
          this.equipmentVoList = [];
          this.showBelongModal = false;
          this.belongForm.belong = null;
        })
        .catch((err) => {
          Loading.close();
          this.$message.error(err.msg);
        });
    },
  },
};
</script>
<style scoped>
.red {
  background-color: #f78989;
  color: #fff;
  display: block;
  width: 100%;
  height: 100%;
}
.dropdown {
  /* height: 23px; */
  line-height: normal;
  color: #909399;
}
::v-deep .el-table th > .cell {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-left {
  width: 255px;
}
.container-left-tree {
  width: 100%;
  overflow: auto;
}
::v-deep .el-tree-node > .el-tree-node__children {
  overflow: visible;
}
.container-right {
  width: calc(100% - 265px);
  height: 100%;
  float: right;
  .buttonOut > div {
    padding-top: 4px;
  }
}
::v-deep .el-range-editor.el-input__inner {
  justify-content: center;
  padding-left: 0px;
  padding-right: 0px;
  width: 360px;
}
::v-deep .el-table-column--selection .cell {
  padding-right: 10px;
}
.device-manage {
  height: 100%;
}
.mr1 {
  width: 120px;
  display: inline-block;
  text-align: right;
  padding-right: 10px;
}
.ml10 {
  margin-left: 10px;
}
</style>
